import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import * as FullStory from '@fullstory/browser';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';

import './i18n';

import { CustomRouter } from './app/components/customRouter';
import { history, store } from './app/store';
import { ExceptionFallback } from './common/components/exceptionFallback';
import { Auth0ProviderWithNavigate } from './features/auth/components/auth0ProviderWithNavigate/Auth0ProviderWithNavigate';
import Fonts from './theme/fonts';
import { App } from './app';
import { ENV_CONFIG } from './config';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';

const isProd = ENV_CONFIG.TIER === 'prod';

// This disables console outputs in prod-production builds
if (import.meta.env.MODE === 'production' && isProd) {
  console.log = () => {
    return;
  };
  console.error = () => {
    return;
  };
  console.debug = () => {
    return;
  };
}

FullStory.init(
  {
    orgId: ENV_CONFIG.FULLSTORY,
    devMode: !isProd,
  },
  ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`),
);

// Initializing with `debug` option:
console.log('Hotjar init');
console.log(ENV_CONFIG.HJ_SITE_ID);
console.log(ENV_CONFIG.HJ_VERSION);

Hotjar.init(ENV_CONFIG.HJ_SITE_ID, ENV_CONFIG.HJ_VERSION, {
  debug: !isProd,
});

Sentry.init({
  release: `mv-dashboard-${ENV_CONFIG.TIER}@` + ENV_CONFIG.RELEASE,
  dsn: ENV_CONFIG.SENTRY.DSN,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: ENV_CONFIG.SENTRY.TRACE_RATE,
  beforeSend(event) {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

const { ToastContainer } = createStandaloneToast({ theme });

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('location', 'root');
      }}
      fallback={ExceptionFallback}
      showDialog
    >
      <ColorModeScript />
      <CustomRouter history={history}>
        <Auth0ProviderWithNavigate>
          <Provider store={store}>
            <Fonts />
            <ChakraProvider theme={theme}>
              <App />
              <ToastContainer />
            </ChakraProvider>
          </Provider>
        </Auth0ProviderWithNavigate>
      </CustomRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
