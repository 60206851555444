import { useTranslation } from 'react-i18next';
import { FiDollarSign, FiFile, FiFilePlus, FiFileText, FiGrid, FiUsers } from 'react-icons/fi';
import iconProfitability from '@assets/icons/profitability.svg';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Heading, Icon, Image, Text } from '@chakra-ui/react';
import { Card } from '@components/card';
import { useDecision } from '@optimizely/react-sdk';

import { useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import { Verticals } from '@/common/interfaces';

import { selectActiveVertical, selectIsUpsideManager } from '../auth/userSlice';

import { selectHasInvDataOnFirstLoad } from './financialReports/invoices/slice';
import { selectPayoutsList } from './financialReports/payouts/slice';

export const Reports = () => {
  const { t } = useTranslation();
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const {
    hasPaymentsManagerAccess,
    hasPayoutManagerAccess,
    hasBillingManagerAccess,
    hasPayoutsReadOnlyAccess,
    hasStatementsInvoicesReadOnlyAccess,
  } = usePermissions();

  const activeVertical = useAppSelector(selectActiveVertical);
  const payoutsData = useAppSelector(selectPayoutsList);
  const hasInvoicesDataOnFirstLoad = useAppSelector(selectHasInvDataOnFirstLoad);

  const [invoicesFlag] = useDecision('md-577', { autoUpdate: true });
  const [payoutsFlag] = useDecision('payouts', { autoUpdate: true });
  const [statementsFlag] = useDecision('statements', { autoUpdate: true });
  const [customReportsFlag] = useDecision('md-1345', { autoUpdate: true });

  return (
    <Grid gridTemplateColumns={'repeat(auto-fill, minmax(365px, 1fr))'} padding={4} gap='4'>
      <GridItem display={'none'}>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/businessImpact', icon: ArrowForwardIcon }}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Image boxSize='18px' src={iconProfitability} marginRight={'8px'} />
              {t('reportsHomePage.businessImpact')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={400} fontSize={'18px'} lineHeight={'28px'}>
                {t('reportsHomePage.businessImpactText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>
      <GridItem>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/reports/profitability', icon: ArrowForwardIcon }}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiDollarSign} boxSize='18px' marginRight={'8px'} />
              {t('reportsHomePage.profitability')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('reportsHomePage.profitabilityText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>
      <GridItem>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/reports/customers', icon: ArrowForwardIcon }}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiUsers} boxSize='18px' marginRight={'8px'} />
              {t('reportsHomePage.customers')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('reportsHomePage.customersText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>
      <GridItem>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/reports/promotions', icon: ArrowForwardIcon }}
        >
          <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiGrid} boxSize='18px' marginRight={'11px'} />
              {t('reportsHomePage.promotions')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('reportsHomePage.promotionsText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>
      {(isUpsideManager ||
        hasPaymentsManagerAccess ||
        hasBillingManagerAccess ||
        hasStatementsInvoicesReadOnlyAccess) && (
        <>
          {(activeVertical === Verticals.Fuel || activeVertical === Verticals.Restaurant) &&
            invoicesFlag.enabled &&
            hasInvoicesDataOnFirstLoad && (
              <GridItem>
                <Card
                  display={'flex'}
                  height={'100%'}
                  button={{ text: t('buttons.view'), to: '/reports/invoices', icon: ArrowForwardIcon }}
                >
                  <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Heading
                      fontSize={'18px'}
                      lineHeight={'22px'}
                      fontWeight={500}
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                    >
                      <Icon as={FiFile} boxSize='18px' marginRight={'11px'} />
                      {t('reportsHomePage.invoices')}
                    </Heading>
                    <Box flex={1} mt={'26px'}>
                      <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                        {t('reportsHomePage.invoicesText')}
                      </Text>
                    </Box>
                  </Flex>
                </Card>
              </GridItem>
            )}
          {statementsFlag.enabled && activeVertical !== Verticals.Grocery && (
            <GridItem>
              <Card
                display={'flex'}
                height={'100%'}
                button={{ text: t('buttons.view'), to: '/reports/statements', icon: ArrowForwardIcon }}
              >
                <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                  <Heading
                    fontSize={'18px'}
                    lineHeight={'22px'}
                    fontWeight={500}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Icon as={FiFileText} boxSize='18px' marginRight={'11px'} />
                    {t('reportsHomePage.statements')}
                  </Heading>
                  <Box flex={1} mt={'26px'}>
                    <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                      {t('reportsHomePage.statementsText')}
                    </Text>
                  </Box>
                </Flex>
              </Card>
            </GridItem>
          )}
        </>
      )}

      {(isUpsideManager || hasPaymentsManagerAccess || hasPayoutManagerAccess || hasPayoutsReadOnlyAccess) &&
        !!payoutsData.rawData.length &&
        payoutsFlag.enabled && (
          <GridItem>
            <Card
              display={'flex'}
              height={'100%'}
              button={{ text: t('buttons.view'), to: '/reports/settlements', icon: ArrowForwardIcon }}
            >
              <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Heading
                  fontSize={'18px'}
                  lineHeight={'22px'}
                  fontWeight={500}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Icon as={FiFilePlus} boxSize='18px' marginRight={'11px'} />
                  {t('reportsHomePage.payouts')}
                </Heading>
                <Box flex={1} mt={'26px'}>
                  <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                    {t('reportsHomePage.payoutsText')}
                  </Text>
                </Box>
              </Flex>
            </Card>
          </GridItem>
        )}
      {customReportsFlag.enabled && (
        <GridItem>
          <Card
            display={'flex'}
            height={'100%'}
            button={{ text: t('buttons.view'), to: '/reports/customReports', icon: ArrowForwardIcon }}
          >
            <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
              <Heading
                fontSize={'18px'}
                lineHeight={'22px'}
                fontWeight={500}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
              >
                <Icon as={FiFile} boxSize='18px' marginRight={'11px'} />
                {t('reportsHomePage.customReports')}
              </Heading>
              <Box flex={1} mt={'26px'}>
                <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                  {t('reportsHomePage.customReportsText')}
                </Text>
              </Box>
            </Flex>
          </Card>
        </GridItem>
      )}
    </Grid>
  );
};
