import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDecision } from '@optimizely/react-sdk';

import { useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import { Verticals } from '@/common/interfaces';
import { ENV_CONFIG } from '@/config';
import { selectIsDemo } from '@/features/dashboard/slice';
import { selectHasInvDataOnFirstLoad } from '@/features/reports/financialReports/invoices/slice';
import { selectPayoutsList } from '@/features/reports/financialReports/payouts/slice';

import { selectActiveVertical, selectIsUpsideManager } from '../../userSlice';

interface FeatureGuardProps {
  Component: React.FC;
}

export const FeatureGuard: React.FC<FeatureGuardProps> = ({ Component }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const payoutsData = useAppSelector(selectPayoutsList);
  const hasInvoicesDataOnFirstLoad = useAppSelector(selectHasInvDataOnFirstLoad);

  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const activeVertical = useAppSelector(selectActiveVertical);
  const isDemo = useAppSelector(selectIsDemo);

  const {
    hasPaymentsManagerAccess,
    hasPayoutManagerAccess,
    hasBillingManagerAccess,
    hasStatementsInvoicesReadOnlyAccess,
    hasPayoutsReadOnlyAccess,
    hasLocationsSettingsManagerAccess,
    hasLocationsSettingsReadOnlyAccess,
  } = usePermissions();

  const [paymentFlag] = useDecision('md-796', { autoUpdate: true });
  const [profileFlag] = useDecision('md-1075', { autoUpdate: true });
  const [invoicesFlag] = useDecision('invoices', { autoUpdate: true });
  const [payoutsFlag] = useDecision('payouts', { autoUpdate: true });
  const [statementsFlag] = useDecision('statements', { autoUpdate: true });
  const [billingFlag] = useDecision('md-809', { autoUpdate: true });
  const [locationSettingsFlag] = useDecision('md-1169', { autoUpdate: true });
  const [notificationsFlag] = useDecision('md-1143', { autoUpdate: true });
  const [customReportsFlag] = useDecision('md-1345', { autoUpdate: true });
  const [merchantSelfRegistrationFlag] = useDecision('merchant-self-registration', { autoUpdate: true });

  const isBillingManager = isUpsideManager || hasBillingManagerAccess || hasPaymentsManagerAccess;
  const isPayoutsManager = isUpsideManager || hasPayoutManagerAccess || hasPaymentsManagerAccess;
  const isLocationsManager = isUpsideManager || hasLocationsSettingsManagerAccess;
  const onlyFuelOrRestaurant = activeVertical === Verticals.Fuel || activeVertical === Verticals.Restaurant;

  const pathName = location.pathname.toLowerCase();

  // Helper functions to check against roles and feature flags
  // Ideally we want to combine this logic with the same logic that's rendering the Links in the nav component
  const allowInvoicesRoute = () => {
    return (
      invoicesFlag.enabled &&
      hasInvoicesDataOnFirstLoad &&
      onlyFuelOrRestaurant &&
      (isBillingManager || hasStatementsInvoicesReadOnlyAccess) &&
      !isDemo
    );
  };

  const allowStatementsRoute = () => {
    return (
      statementsFlag.enabled &&
      onlyFuelOrRestaurant &&
      (isBillingManager || hasStatementsInvoicesReadOnlyAccess) &&
      !isDemo
    );
  };

  const allowPayoutsRoute = () => {
    return (
      payoutsFlag.enabled && !!payoutsData.rawData.length && (isPayoutsManager || hasPayoutsReadOnlyAccess) && !isDemo
    );
  };

  const allowCapacityRoute = () => {
    if (
      (ENV_CONFIG.TIER === 'prod' && activeVertical === Verticals.Fuel) ||
      (ENV_CONFIG.TIER === 'alpha' && activeVertical !== Verticals.Restaurant)
    ) {
      return true;
    }
    return false;
  };

  const allowPaymentMethodsRoute = () => {
    return (billingFlag.enabled || paymentFlag.enabled) && (isBillingManager || isPayoutsManager) && !isDemo;
  };

  const allowBillingMethodsRoutes = () => {
    return billingFlag.enabled && isBillingManager && !isDemo;
  };

  const allowPayoutMethodRoutes = () => {
    return paymentFlag.enabled && isPayoutsManager && !isDemo;
  };

  const allowROLocationsSettingsRoutes = () => {
    return locationSettingsFlag.enabled && (isLocationsManager || hasLocationsSettingsReadOnlyAccess) && !isDemo;
  };

  const allowMangerLocationsSettingsRoutes = () => {
    return locationSettingsFlag.enabled && isLocationsManager && !isDemo;
  };

  const allowNotificationsRoutes = () => {
    return notificationsFlag.enabled && !isDemo;
  };

  const allowProfileRoute = () => {
    return profileFlag.enabled && !isDemo;
  };

  const allowProfilesRoute = () => {
    return isUpsideManager && allowProfileRoute();
  };

  const allowCustomReportsRoute = () => {
    return customReportsFlag.enabled;
  };

  const allowMerchantSelfRegistrationRoute = () => {
    return merchantSelfRegistrationFlag.enabled && !isDemo;
  };

  // Begin route check logic using helper functions
  // Note: that this logic protects against a user manually entering a URL
  useEffect(() => {
    if (pathName.includes('/registration') || /registration\/[1-5]/.test(pathName)) {
      if (!allowMerchantSelfRegistrationRoute()) {
        navigate('/login');
      }
    } else if (pathName === '/reports/financial/invoices') {
      if (!allowInvoicesRoute()) {
        navigate('/home');
      }
    } else if (pathName === '/reports/financial/statements') {
      if (!allowStatementsRoute()) {
        navigate('/home');
      }
    } else if (pathName.includes('/reports/customreports')) {
      if (!allowCustomReportsRoute()) {
        navigate('/home');
      }
    } else if (pathName === '/reports/financial/payouts') {
      if (!allowPayoutsRoute()) {
        navigate('/home');
      }
    } else if (pathName === '/explore/capacity') {
      if (!allowCapacityRoute()) {
        navigate('/home');
      }
    } else if (pathName === '/settings/payments') {
      if (!allowPaymentMethodsRoute()) {
        navigate('/home');
      }
    } else if (pathName.includes('/settings/payments/billing')) {
      if (!allowBillingMethodsRoutes()) {
        navigate('/home');
      }
    } else if (pathName.includes('/settings/payments/payout')) {
      if (!allowPayoutMethodRoutes()) {
        navigate('/home');
      }
    } else if (pathName === '/settings/locations') {
      if (!allowROLocationsSettingsRoutes()) {
        navigate('/home');
      }
    } else if (pathName.includes('/settings/locations/new') || pathName.includes('/settings/locations/manage')) {
      if (!allowMangerLocationsSettingsRoutes()) {
        navigate('/home');
      }
    } else if (pathName.includes('/settings/notification')) {
      if (!allowNotificationsRoutes()) {
        navigate('/home');
      }
    } else if (pathName === '/profile') {
      if (!allowProfileRoute()) {
        navigate('/home');
      }
    } else if (pathName === '/profiles') {
      if (!allowProfilesRoute()) {
        navigate('/home');
      }
    }
  }, [pathName, navigate]);

  return <Component />;
};
